import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MagicSliderDots from 'react-magic-slider-dots';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './magic-dots.css';
import style from './OffersModal.module.css';
import cross from '../../images/cross.svg';
import { offersAction } from '../../store/actions';
import { IOffer } from '../../models';
import { Autocomplete, CustomLoader } from '..';
import arrow from '../../images/silver-arrow.svg';

const years = [
  { id: 1, name: '2020 - 2021', value: '2020' },
  { id: 2, name: '2021 - 2022', value: '2021' },
  { id: 3, name: '2022 - 2023', value: '2022' },
  { id: 4, name: '2023 - 2024', value: '2023' },
  { id: 5, name: '2024 - 2025', value: '2024' },
  { id: 6, name: '2025 - 2026', value: '2025' },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: false,
  dotsClass: style.active_dot_background,
  appendDots: (dots) => (
    <MagicSliderDots dotsClass={style.active_dot_background} dots={dots} numDotsToShow={3} dotWidth={30} />
  ),
};

export const OffersModal = ({ close }: { close: any }) => {
  const { selectedCollege } = useSelector((state: any) => state.collegesState);
  const { offers } = useSelector((state: any) => state.offersState);

  const [sortedOffers, setSortedOffers] = useState<IOffer[]>([]);
  const [offerYear, setOfferYear] = useState(years[3]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isYearSelectActive, setIsYearSelectActive] = useState(false);

  const [isScrolable, setIsScrolable] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const childRef = useRef<any>();

  const refTable = useRef<HTMLDivElement>(null);
  const refTitle = useRef<HTMLDivElement>(null);
  const refScrollBlock = useRef<HTMLDivElement | null>(null);

  const studentDetails = ['SAI/EFC', 'State', 'GPA', 'SAT', 'ACT', 'Admission Type'];
  const basedScholarships = ['Need Based Aid', 'Merit Scholarships'];

  useEffect(() => {
    fetchTheOffers();
  }, [offerYear, selectedCollege]);

  useEffect(() => {
    sortTheOffers();
  }, [offers]);

  useEffect(() => {
    checkInitialFetchNeed();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (refTable.current && refTitle.current && refScrollBlock.current) {
        setIsScrolable(
          refTable.current.clientWidth - refTitle.current.clientWidth < refScrollBlock.current.scrollWidth,
        );
      }
    }
  }, [isLoading, sortedOffers]);

  useEffect(() => {
    if (isYearSelectActive) {
      const input = document.getElementById('year-input');
      input?.focus();
    }
  }, [isYearSelectActive]);

  window.onresize = () => {
    setWindowWidth(window.innerWidth);
    if (refTable.current && refTitle.current && refScrollBlock.current) {
      setIsScrolable(refTable.current.clientWidth - refTitle.current.clientWidth < refScrollBlock.current.scrollWidth);
    }
  };

  const checkInitialFetchNeed = () => {
    if (offers[0]?.collegeId !== selectedCollege?.id || offers[0].year !== Number(offerYear.value)) {
      fetchTheOffers();
    } else {
      sortTheOffers();
    }
  };

  const sortTheOffers = (): void => {
    setIsLoading(true);
    const arrForSort: IOffer[] = [...offers];
    setSortedOffers(
      arrForSort.sort((a: IOffer, b: IOffer) => {
        if (!a.efcValues || !b.efcValues) {
          return 0;
        }
        return a.efcValues.min - b.efcValues.min;
      }),
    );
    setIsLoading(false);
  };

  const fetchTheOffers = (): void => {
    setIsLoading(true);
    if (selectedCollege) {
      const { ipeds } = selectedCollege;
      dispatch(offersAction.getCrowdsourcedOffers({ ipeds, year: offerYear.value }));
    }
  };

  const selectYears = (year: any) => {
    setOfferYear(year);
    setIsYearSelectActive(false);
  };

  return (
    <div
      onClick={() => setIsYearSelectActive(false)}
      onTouchStart={(e) => e.stopPropagation()}
      onTouchEnd={(e) => e.stopPropagation()}
      className={style.offers_modal}
    >
      <div className={style.modal_content}>
        <img onClick={close} src={cross} alt={'close'} />
        {selectedCollege && <h2>Crowdsourced Offers for {selectedCollege.name}</h2>}
        <div className={style.offers_years}>
          <h4>Academic School Year</h4>
          <div className={style.select_wrapper}>
            <div className={style.years_select}>
              <img
                src={arrow}
                alt="arrow"
                className={isYearSelectActive ? style.arrow_down : style.arrow_up}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsYearSelectActive((prev) => !prev);
                }}
              />
              <input
                type="text"
                readOnly
                id="year-input"
                value={offerYear.name}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsYearSelectActive(true);
                }}
              />
              {isYearSelectActive && (
                <Autocomplete
                  classN="offers-table"
                  isBudgetItems={false}
                  ref={childRef}
                  onclick={selectYears}
                  filteredItem={years}
                />
              )}
            </div>
            {!isLoading && offers.length !== 0 && (
              <p className={style.sort_information}>*Offers arranged from lowest SAI to highest SAI</p>
            )}
          </div>
        </div>
        {isLoading && (
          <div className={style.loader_wrapper}>
            <CustomLoader size="big" />
          </div>
        )}
        {!isLoading && offers.length === 0 && (
          <div className={style.empty_offers_list}>
            <h2>No Offers!</h2>
          </div>
        )}
        {!isLoading &&
          offers.length !== 0 &&
          (windowWidth > 520 ? (
            <div className={style.offers_table_container}>
              <div
                ref={refTable}
                className={!isScrolable ? `${style.offers_table} ${style.few_offers}` : `${style.offers_table}`}
              >
                <div
                  ref={refTitle}
                  className={!isScrolable ? `${style.sticky_block} ${style.few_offers}` : style.sticky_block}
                >
                  <div className={style.empty_block}>not visible</div>
                  <div className={style.students_details_title}>Student Details</div>
                  <div className={style.students_details_body__sticky}>
                    {studentDetails.map((detail: string) => {
                      return (
                        <p className={style.subtitle_item} key={detail}>
                          {detail}
                        </p>
                      );
                    })}
                  </div>
                  <div className={style.students_details_title}>Need Aid/ Merit Scholarships</div>
                  <div className={style.students_details_body__sticky}>
                    {basedScholarships.map((detail: string) => {
                      return (
                        <p className={style.subtitle_item} key={detail}>
                          {detail}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div
                  ref={refScrollBlock}
                  className={!isScrolable ? `${style.scroll_block} ${style.few_offers}` : style.scroll_block}
                >
                  <div className={style.offers_flex_container}>
                    {sortedOffers.map((offer: any, index: number) => {
                      return (
                        <div className={style.offer_item_container} key={offer.id}>
                          <div className={style.offer_item_flex}>
                            <div className={style.count_offer}>Offer {index + 1}</div>
                            <div className={style.students_details_title}>mckdcmkd</div>
                            <div className={style.students_details_body__scroll}>
                              <p className={style.value_item}>{offer.efc ? offer.efc : '-'}</p>
                              <p className={style.value_item}>{offer.state ? offer.state.name : '-'}</p>
                              <p className={style.value_item}>{offer.gpa ? offer.gpa : '-'}</p>
                              <p className={style.value_item}>{offer.sat ? offer.sat : '-'}</p>
                              <p className={style.value_item}>{offer.act ? offer.act : '-'}</p>
                              <p className={style.value_item}>
                                {offer.admissionType.name ? offer.admissionType.name : '-'}
                              </p>
                            </div>
                            <div className={style.students_details_title}>mckdcmkd</div>
                            <div className={style.students_details_body__scroll}>
                              <p className={style.value_item}>
                                {offer.grantFinancialNeed
                                  ? `$${(+offer.grantFinancialNeed).toLocaleString('en-US')}`
                                  : '-'}
                              </p>
                              <p className={style.value_item}>
                                {offer.grantNonFinancialNeed
                                  ? `$${(+offer.grantNonFinancialNeed).toLocaleString('en-US')}`
                                  : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Slider {...settings}>
              {sortedOffers.map((offer: any, index: number) => (
                <div key={offer.id} className={style.offers_table_container}>
                  <div ref={refTable} className={`${style.offers_table} ${style.few_offers}`}>
                    <div ref={refTitle} className={`${style.sticky_block} ${style.few_offers}`}>
                      <div className={style.empty_block}>not visible</div>
                      <div className={style.students_details_title}>Student Details</div>
                      <div className={style.students_details_body__sticky}>
                        {studentDetails.map((detail: string) => {
                          return (
                            <p className={style.subtitle_item} key={detail}>
                              {detail}
                            </p>
                          );
                        })}
                      </div>
                      <div className={style.students_details_title}>Need Aid/ Merit Scholarships</div>
                      <div className={style.students_details_body__sticky}>
                        {basedScholarships.map((detail: string) => {
                          return (
                            <p className={style.subtitle_item} key={detail}>
                              {detail}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    <div ref={refScrollBlock} className={`${style.scroll_block} ${style.few_offers}`}>
                      <div className={style.offers_flex_container}>
                        <div className={style.offer_item_container} key={offer.id}>
                          <div className={style.offer_item_flex}>
                            <div className={style.count_offer}>Offer {index + 1}</div>
                            <div className={style.students_details_title}>mckdcmkd</div>
                            <div className={style.students_details_body__scroll}>
                              <p className={style.value_item}>{offer.efc ? offer.efc : '-'}</p>
                              <p className={style.value_item}>{offer.state ? offer.state.name : '-'}</p>
                              <p className={style.value_item}>{offer.gpa ? offer.gpa : '-'}</p>
                              <p className={style.value_item}>{offer.sat ? offer.sat : '-'}</p>
                              <p className={style.value_item}>{offer?.act ? offer.act : '-'}</p>
                              <p className={style.value_item}>
                                {offer?.admissionType?.name ? offer?.admissionType?.name : '-'}
                              </p>
                            </div>
                            <div className={style.students_details_title}>mckdcmkd</div>
                            <div className={style.students_details_body__scroll}>
                              <p className={style.value_item}>
                                {offer.grantFinancialNeed
                                  ? `$${(+offer.grantFinancialNeed).toLocaleString('en-US')}`
                                  : '-'}
                              </p>
                              <p className={style.value_item}>
                                {offer.grantNonFinancialNeed
                                  ? `$${(+offer.grantNonFinancialNeed).toLocaleString('en-US')}`
                                  : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ))}
      </div>
    </div>
  );
};
